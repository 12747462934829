@import '../../styles/media-queries';

.section {
  @include for-screen-up(1366) {
    padding: 0 4rem;
  }

  &__content {
    width: 100%;
    max-width: 184rem;
    margin: auto;
    padding: 4rem 1.8rem 0;
    box-sizing: border-box;

    @include for-screen-up(640) {
      padding: 4.4rem 2rem 0;
    }

    @include for-screen-up(960) {
      padding: 4.4rem 5rem 0;
    }

    @include for-screen-up(1024) {
      padding: 7rem 6rem 0;
    }

    @include for-screen-up(1366) {
      padding: 8rem 5.6rem 0;
    }
  }

  &__analyze-adv {
    margin: 0 -1.8rem;

    @include for-screen-up(640) {
      margin: 0 -2rem;
    }

    @include for-screen-up(960) {
      margin: 0 -3.1rem;
    }

    @include for-screen-up(1024) {
      margin: 0 -4rem;
    }

    @include for-screen-up(1366) {
      margin: 0 -5.6rem;
    }
  }

  &__heading {
    margin-bottom: 1.3rem;
    padding: 0;
    color: var(--text-color-primary-black);
    font-size: 4rem;

    line-height: 1.3;

    @include for-screen-up(360) {
      font-size: 4.6rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 1rem;
      font-size: 5rem;
    }

    @include for-screen-up(960) {
      font-size: 6rem;
    }

    @include for-screen-up(1024) {
      font-size: 6.5rem;
    }

    @include for-screen-up(1366) {
      padding: 0;
      margin-bottom: 2.5rem;
      font-size: 7.5rem;
    }

    @include for-screen-up(1680) {
      font-size: 9rem;
    }
  }

  &__tabs {
    margin: 0 0 1.5rem;

    @include for-screen-up(640) {
      margin: 0 0 1.5rem;
    }

    @include for-screen-up(960) {
      margin: 0 0 1.8rem;
    }

    @include for-screen-up(1366) {
      margin: 0 0 2.5rem;
    }
  }

  &__possibilities {
    margin: 0 -1.8rem;

    @include for-screen-up(960) {
      margin: 0 -1rem;
    }

    @include for-screen-up(1024) {
      margin: 0 -2rem;
    }

    @include for-screen-up(1366) {
      margin: 0 -5.6rem;
    }
  }
}
