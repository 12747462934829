@import '../../styles/media-queries';
@import '../../styles/functions';

.section {
  $section: &;

  z-index: 3;
  overflow: hidden;
  position: relative;

  margin: -8.1rem auto auto;

  @include for-screen-up(640) {
    margin-top: -12.2rem;
  }

  @include for-screen-up(1024) {
    margin-top: -13rem;
  }

  &__inner {
    background-color: var(--bg-color-primary-white);
    margin: 0 -2.1rem;
    padding: 8.1rem 3.9rem 0;

    @include for-screen-up(375) {
      clip-path: inset(0 0 0 0 round 8rem 8rem 0 0);
    }

    @include for-screen-up(640) {
      clip-path: inset(0 0 0 0 round 13.1rem 13.1rem 0 0);
      margin: 0 -3rem;
      padding: 8.1rem 6rem 0;
    }

    @include for-screen-up(1024) {
      clip-path: inset(0 0 0 0 round 14rem 14rem 0 0);
      padding: 8.1rem 6rem 0;
    }
  }

  &__logo-icon {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  &__content {
    display: flex;
    width: 100%;
    max-width: 192rem;
    margin: auto;
    flex-direction: column;
    padding: 6.8rem 1.8rem 9.2rem;
    box-sizing: border-box;
    align-items: center;

    @include for-screen-up(640) {
      padding: 7rem 6rem 7.7rem;
    }

    @include for-screen-up(960) {
      flex-direction: row;
      align-items: center;
      gap: 4.7rem;
      padding: 0;
      height: 88.9rem;

      @supports (container-type: size) {
        container-type: size;
        gap: pxTOcqw(47);
        height: pxTOcqw(889);
      }
    }

    @include for-screen-up(1680) {
      gap: 4.7rem;
      height: 88.9rem;
    }
  }

  &__heading {
    margin-bottom: 1rem;
    color: var(--text-color-primary-black);
    font-size: 3.4rem;
    line-height: 1.36;

    @include for-screen-up(375) {
      font-size: 4rem;
    }

    @include for-screen-up(640) {
      font-size: 5rem;
    }

    @include for-screen-up(960) {
      margin-bottom: 2.5rem;
      font-size: 6.5rem;

      @supports (container-type: size) {
        margin-bottom: pxTOcqw(25);
        font-size: pxTOcqw(65);
      }
    }

    @include for-screen-up(1680) {
      margin-bottom: 2.5rem;
      font-size: 8.2rem;
    }
  }

  &__summary {
    margin-bottom: 2.2rem;
    color: var(--text-color-primary-black);
    font-family: Gilroy, sans-serif;
    font-size: 1.7rem;

    line-height: 1.7;

    @include for-screen-up(360) {
      font-size: 2rem;
    }

    @include for-screen-up(640) {
      margin-bottom: 4.6rem;
      font-size: 2rem;
    }

    @include for-screen-up(960) {
      font-size: 2.6rem;

      @supports (container-type: size) {
        font-size: pxTOcqw(26);
      }
    }

    @include for-screen-up(1680) {
      font-size: 2.6rem;
    }
  }

  &__column {
    width: 100%;
    flex: 0 1 50%;

    @include for-screen-up(640) {
      max-width: 87%;
    }

    @include for-screen-up(960) {
      max-width: none;
    }

    &:first-child {
      #{$section}__column-content {
        @include for-screen-up(960) {
          width: 65.2rem;
          margin-left: auto;

          @supports (container-type: size) {
            width: pxTOcqw(652);
          }
        }
        @include for-screen-up(1680) {
          width: 65.2rem;
        }
      }
    }
  }

  &__security-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    margin: auto;
    gap: 1.9rem;
    width: 100%;
    padding-bottom: 4.2rem;

    @include for-screen-up(375) {
      gap: 2.5rem;
    }

    @include for-screen-up(480) {
      width: 45.5rem;
      gap: 3.2rem;
    }

    @include for-screen-up(960) {
      width: 45.5rem;
      margin-left: 15rem;
      gap: 3.4rem;

      //padding-bottom: 5.7rem;

      @supports (container-type: size) {
        width: pxTOcqw(455);
        margin-left: pxTOcqw(150);
        gap: pxTOcqw(34);
      }
    }
    @include for-screen-up(1680) {
      width: 45.5rem;
      margin-left: 15rem;
      gap: 3.4rem;
    }
  }

  &__security-item {
    flex: 0 1 calc(50% - 1.9rem);

    display: flex;
    position: relative;

    @include for-screen-up(640) {
      width: 19.9rem;
    }

    @include for-screen-up(960) {
      width: 21rem;
    }

    @include for-screen-up(1680) {
      width: 21rem;
    }

    &:nth-child(2n) {
      top: 9.2rem;

      @include for-screen-up(640) {
        top: 15.6rem;
      }

      @include for-screen-up(960) {
        top: 6.7rem;

        @supports (container-type: size) {
          top: pxTOcqw(157);
        }
      }
      @include for-screen-up(1680) {
        top: 15.7rem;

        @supports (container-type: size) {
          top: pxTOcqw(157);
        }
      }
    }
  }
}
