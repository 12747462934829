@import '../../styles/media-queries';

.partners {
  position: relative;

  &__pacman {
    width: 8.6rem;
    height: 8.6rem;
    display: flex;
    justify-content: flex-end;
    margin: auto;
    position: absolute;
    top: 0;
    left: 3.2rem;
    bottom: 0;
    z-index: 1;

    @include for-screen-up(960) {
      left: 6.4rem;
    }

    @include for-screen-up(1440) {
      width: 11.6rem;
      height: 11.6rem;
    }

    &:before {
      content: '';
      background: var(--bg-color-primary-white);
      position: absolute;
      top: 0;
      bottom: 0;
      left: -3.2rem;
      right: 5.8rem;
      z-index: -1;

      @include for-screen-up(960) {
        left: -6.4rem;
      }
    }
  }

  &__fig {
    display: flex;
    height: 8.2rem;
    align-items: center;
    justify-content: center;

    @include for-screen-up(1024) {
      height: 8.8rem;
    }

    @include for-screen-up(1680) {
      height: 12rem;
    }
  }

  &__img {
    transform: scale(0.78);

    @include for-screen-up(1024) {
      transform: scale(0.84);
    }

    @include for-screen-up(1680) {
      transform: none;
    }
  }
}

.hero-section {
  position: relative;
  top: 50vh;
  overflow: hidden;
  font: 900 1rem sans-serif;
  min-height: 100vh;
}
