@import '../../styles/media-queries';
@import '../../styles/functions';

.security-card {
  $card: &;
  width: 100%;
  height: 0;
  padding-bottom: 132%;
  position: relative;
  z-index: 1;

  &__bg {
    border-radius: 3rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    opacity: 0;
    transform: scale(0);
    transition: transform 0.6s ease;

    #{$card}_active & {
      opacity: 1;
      transform: scale(1);
    }

    @include for-screen-up(640) {
      border-radius: 3.8rem;
    }

    @include for-screen-up(960) {
      border-radius: 4rem;

      @supports (container-type: size) {
        border-radius: pxTOcqw(40);
      }
    }
  }

  &__img {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;

    opacity: 0;
    transition: clip-path 0.6s ease;
    clip-path: inset(50% 50% 50% 50%);

    transform: scale(0.7) translate(-70%, -70%);

    @include for-screen-up(375) {
      transform: scale(0.8) translate(-60%, -60%);
    }

    @include for-screen-up(640) {
      transform: scale(0.9) translate(-55%, -55%);
    }

    @include for-screen-up(960) {
      transform: scale(0.55) translate(-90%, -90%);
    }

    @include for-screen-up(1024) {
      transform: scale(0.6) translate(-83%, -83%);
    }

    @include for-screen-up(1280) {
      transform: scale(0.7) translate(-70%, -70%);
    }

    @include for-screen-up(1440) {
      transform: scale(0.8) translate(-62%, -62%);
    }

    @include for-screen-up(1680) {
      transform: translate(-50%, -50%);
    }

    #{$card}_active & {
      opacity: 1;
      clip-path: inset(0% 0% 0% 0%);
    }
  }
}
