.home {
  &__partners {
    &:not([class*='is-visible']) {
      height: auto;
      min-height: 12rem;
    }

    &__features {
      &:not([class*='is-visible']) {
        min-height: 108rem;
      }
    }

    &__methods {
      &:not([class*='is-visible']) {
        min-height: 87.3rem;
      }
    }

    &__program {
      &:not([class*='is-visible']) {
        min-height: 137.7rem;
      }
    }

    &__security {
      &:not([class*='is-visible']) {
        min-height: 97rem;
      }
    }
  }
}
